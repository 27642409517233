.box h2 {
    margin: 0;
}

.box p {
    line-height: 1.6;;
    max-width: 700px;
}

.box a {
    color: var(--teal);
}

.box nav,
.detail-inner {
    align-items: center;
    display: flex;
    gap: 10px;
}

.box nav {
    justify-content: flex-end;
}

.detail {
    background: var(--gray);
    padding: 10px;
    margin-bottom: 6px;
    width: 720px;
    max-width: 100%;
}

.detail i {
    font-size: 18rem;
    margin-left: -8px;
}

.detail-inner {
    cursor: pointer;
    justify-content: space-between;
    user-select: none;
}

.detail-inner > b {
    font-size: 15rem;
}

.cookie {
    margin: 20px 0 0 0;
}

.cookie > p {
    margin-top: 6px;
}

.toggle-input {
    display: block;
    height: 26px;
    min-width: 56px;
    position: relative;
    width: 56px;
}

.toggle-input input {
    height: 0;
    opacity: 0;
    width: 0;
}

.toggle-input span {
    background-color: var(--dark-blue);
    border-radius: 13px;
    cursor: pointer;
    display: block;
    inset: 0;
    position: absolute;
    transition: .4s;
}

.toggle-input span::before {
    background-color: #fff;
    border-radius: 50%;
    bottom: 3px;
    content: '';
    height: 20px;
    left: 4px;
    position: absolute;
    width: 20px;
    transition: .4s;
}

.toggle-input input:checked + span {
    background-color: var(--teal);
}

.toggle-input input:checked + span::before {
    transform: translateX(28px);
}

.toggle-input input:disabled + span {
    opacity: .4;
}