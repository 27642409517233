:root {
	--adm-black: #2D2E33;
	--adm-teal-light: #31C4C4;
	--adm-teal: #2BABB6;
	--adm-blue: #2FA7CA;
	--adm-grey-light: #F2F3F5;
	--adm-grey: #6E717D;
	--adm-grey-dark: #353942;
	--adm-red: #CA445A;

  	--app-min-width: 1500px;
	--chbx-s: 24px;
	--container-w: 1600px;
	--container-padding: 20px;
	--section-spacing: 50px;
  	--navbar-h: 80px;
	--p-color: #455a64;
	--p-dark-color: #29434e;
	--p-light-color: #718792;
	--s-color: #ff8f00;
	--s-dark-color: #c43e00;
	--green: #4caf50;
	--promo-green: #fff0ed ; /* #E9F8F5 */
	--yellow: #ffae42;
	--light-green: #c8e6c9;
	--lighter-green: #e8f5e9;
	--red: #e53935;
  	--strong-red: #e91919;

	--link-color: #039be5;
	--check-color: #f0f0f0;
	--border-color: #ccc;
	--border-dark-color: #aaa;

  	--dark-blue: #333; /* primary color #365a71 */
	--teal: #a31916; /* secondary color #00b5ad */
	--gray: #f6f7fa;
	--gray-1: #e6e7ea;
	--background-grey: #f2f2fa;
	--payu: #a6c307;

	--car-search-field-h: 57px;
	--car-search-input-h: 36px;
	--car-search-link-w: 151px;
	--car-search-w: 1200px;

	--input-border-c: #ddd; /* default #d2d5e2 */

	--ck-color-base-foreground: #fff !important;
	--ck-color-base-background: var(--gray) !important;
	--ck-color-base-border: var(--input-border-c) !important;
	--ck-border-radius: 12px !important;

	--gray-0: #fafbfc;
    --gray-2: #e1e4e8;
    --gray-4: #959da5;
    --gray-6: #586069;
    --gray-8: #2f363d;
    --gray-9: #242c33;
    --gray-10: #1b1f23;
}

* {
	font-family: 'Maven Pro', sans-serif;
	box-sizing: border-box;
	color: var(--dark-blue);
}

.ck-editor__main a {
	font-weight: bold;
	text-decoration: underline;
}

html,
body {
	color: var(--dark-blue);
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    	Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	margin: 0;
  	padding: 0;
  	scroll-padding-top: 150px;
}

html {
  	font-size: 1px;
}

body {
 	font-size: 14rem;
}

.container {
	max-width: var(--container-w);
    padding: 0 var(--container-padding);
    margin: auto;
}

.relative {
	position: relative;
}

.bt {
	background: white;
	border: none;
	cursor: pointer;
}

h1 {
	font-size: 20rem;
}

h2 {
	font-size: 18rem;
}

h3 {
	font-size: 16rem;
}

blockquote {
	border-left: 3px solid var(--input-border-c);
	line-height: 1.6;
	margin-left: 0;
	padding: 10px;
}

a {
	color: inherit;
	text-decoration: none;
}

hr {
	border-top: 1px solid var(--input-border-c);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 	-webkit-appearance: none;
 	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.flash-message,
.flash-message > i,
.loading-dialog,
.loading-dialog > i,
.loading-dialog > span {
	color: white;
}

.loading-dialog,
.flash-message {
	position: fixed;
	padding: 20px 50px;
	right: 60px;
  	text-align: center;
	top: 100px;
	transition: all .3s ease-in-out;
	z-index: 10000;
}

.flash-message.message {
	background: var(--green);
}

.flash-message.error {
	background: var(--red);
}

.flash-message.warning,
.loading-dialog.message {
	background: var(--yellow);
}

.flash-message i {
	position: absolute;
	right: 10px;
	top: 17px;
}

.clickable {
	cursor: pointer;
	opacity: .8;
}

.clickable:hover {
	opacity: 1;
}

.min-height {
	min-height: calc(100vh - 550px);
}

.max-width {
	max-width: 800px;
}

progress[value] {
	-webkit-appearance: none;
	appearance: none;
	border-radius: 6px;
	height: 12px;
	overflow: hidden;
}

::-webkit-progress-bar {
	background: var(--gray);
	border: 1px dashed var(--input-border-c);
}

::-webkit-progress-value {
	background: var(--teal);
	transition: width .3s;
}

@media screen and (min-width: 801px) {
	h1 {
		font-size: 34rem;
	}

	h2 {
		font-size: 26rem;
	}

	h3 {
		font-size: 19rem;
	}
}