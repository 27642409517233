.totop {
    background: var(--teal);
    border: 1px solid white;
    border-radius: 50%;
    bottom: 30px;
    cursor: pointer;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 30px;
    text-align: center;
    width: 60px;
    z-index: 1000;
}

.totop i {
    color: white;
    margin-top: -5px;
    user-select: none;
}

.totop {
    animation: jump .6s normal forwards ease-in-out;
}

.totop:hover i {
    animation-name: pulse;
    animation-duration: .3s;
}

@keyframes jump {
    from { height: 57px; bottom: 30px }
    50% { height: 63px; bottom: 100px }
    to { height: 60px; bottom: 30px }
  }

@keyframes pulse {
50% { transform: scale(1.4) }
}