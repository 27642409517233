.curtain {
    align-items: center;
    backdrop-filter: blur(1%);
    background-color: rgba(50,50,50,0.4);
    display: flex;
    inset: 0;
    justify-content: center;
    opacity: 0;
    position: fixed;
    transition: all .3s ease-out;
    transition-delay: .2s;
    z-index: 9999;
}

.content {
    background: white;
    border: 1px solid var(--input-border-c);
    border-radius: 5px;
    box-shadow: 0 4px 7px rgba(0,0,0,0.2), 0 4px 7px rgba(0,0,0,0.3);
    min-height: 60px;
    min-width: 100px;
    max-height: 85vh;
    max-width: 90vw;
    opacity: 0;
    overflow-y: auto;
    padding: 20px;
    transform: translateY(-100px);
    transition: all .3s ease-out;
}

.curtain.active {
    opacity: 1;
    transition-delay: 0s;
    transition-duration: .2s;
}

.content.active {
    opacity: 1;
    transform: translateY(0);
    transition-delay: .2s;
    transition-duration: .4s;
}