.wrap {
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.bar {
    background: var(--teal);
    height: 2px;
    transition: width 0.5s;
}